import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { useSortableTable } from 'hooks/useSortableTable';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';
import { TransactionInstance } from 'types/shared-types';

import { TransactionTableRow } from './table-row';

type TransactionTablePropsType = {
  data: TransactionInstance[];
  tableFilters: UseTableFiltersType;
  isFilterApplied: boolean;
  isSearchApplied: boolean;
  isPending?: boolean;
};

const TABLE_HEAD = [
  'ID',
  'CUSTOMER NAME',
  'DESCRIPTION',
  'DATE',
  'JURISDICTION',
  'AMOUNT',
  'TAX LIABILITY',
  'CURRENCY',
  'CURRENCY CONVERSION RATE',
  'CONVERTED CURRENCY',
  'TRANSACTION SOURCE',
  'EXEMPT',
  'MARKETPLACE',
  'STATUS',
];

const COLUMN_WIDTHS = {
  ID: '100px',
  'CUSTOMER NAME': '163px',
  DESCRIPTION: '253px',
  DATE: '120px',
  JURISDICTION: '110px',
  AMOUNT: '133px',
  'TAX LIABILITY': '157px',
  'TRANSACTION SOURCE': '166px',
  EXEMPT: '60px',
  MARKETPLACE: '60px',
  STATUS: '114px',
};

const HIDE_COLUMNS = [
  'TRANSACTION SOURCE',
  'EXEMPT',
  'MARKETPLACE',
  'CURRENCY',
  'CURRENCY CONVERSION RATE',
  'CONVERTED CURRENCY',
];
const NUMERIC_COLUMNS = ['AMOUNT', 'TAX LIABILITY', 'CURRENCY CONVERSION RATE'];
const ALL_SORTABLE_FIELDS = ['date', 'state'];
const SORT_HEADERS = ['DATE', 'JURISDICTION'];
const FIELD_MAPPING = {
  DATE: 'date',
  JURISDICTION: 'state',
};

export const TransactionTable = ({
  data,
  isFilterApplied,
  isSearchApplied,
  tableFilters,
  isPending,
}: TransactionTablePropsType) => {
  const isDataEmpty = !data || data.length === 0;
  const { order_by, setFilters } = tableFilters;
  const DEFAULT_VISIBLE_COLUMNS = TABLE_HEAD.filter(header => !HIDE_COLUMNS.includes(header));
  const [visibleColumns, setVisibleColumns] = useState<string[]>(DEFAULT_VISIBLE_COLUMNS);

  const handleToggleColumn = (columns: string[]) => {
    setVisibleColumns(columns);
  };

  const { handleSort, getSortIcon } = useSortableTable({
    initialOrderBy: order_by,
    allSortableFields: ALL_SORTABLE_FIELDS,
    fieldMapping: FIELD_MAPPING,
    setFilters,
  });

  if (!isPending && isDataEmpty) {
    return (
      <TableEmptyState tableName="Transactions" isFilterApplied={isFilterApplied} isSearchApplied={isSearchApplied} />
    );
  }

  return (
    <KDataTable
      headers={TABLE_HEAD}
      numericCols={NUMERIC_COLUMNS}
      defaultVisibleColumns={visibleColumns}
      onVisibleColumnsChange={handleToggleColumn}
      showColumnFilter
      sortHeaders={SORT_HEADERS}
      getSortIcon={getSortIcon}
      handleSort={handleSort}
      columnWidths={COLUMN_WIDTHS}
      isPending={isPending}
    >
      {data.map(({ id, addresses, ...rest }: TransactionInstance) => (
        <TransactionTableRow key={id} id={id} addresses={addresses} {...rest} visibleColumns={visibleColumns} />
      ))}
      {isPending && <TableRowSkeleton columns={TABLE_HEAD} />}
    </KDataTable>
  );
};
