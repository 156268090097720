import { Box, CheckboxGroup, HStack, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { dataSourceQueryKeys, getFileSources } from 'apis/data-sources-apis';
import { FILINGS_STATE_KEY, getFilings } from 'apis/filing-apis';
import { getRegistrations, REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from 'components/ui/accordion';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import DatePicker from 'components/ui/date-picker';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { getStatesOrProvinces } from 'constants/app-constants';
import { FILE_SOURCE_MAP } from 'constants/source-constants';
import { addressStatusOptions, statusOptions, transactionTypeOptions } from 'constants/transactions';
import { useOrg } from 'hooks/useOrg';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useEffect, useState } from 'react';
import { ApiResponse, FilingInstance, RegistrationInstance, Source } from 'types/shared-types';
import { toDateShort } from 'utils/dates';
import { getHumanReadableString } from 'utils/enum-helpers';

type TransactionFilterProps = {
  onClose: () => void;
  tableFilters: UseTableFiltersType;
};

export const TransactionFilter = ({
  onClose,
  tableFilters: {
    date__gte,
    date__lte,
    address_status__in,
    transaction_source,
    transaction_type,
    state_code,
    status,
    country,
    filing_id,
    marketplace,
    setFilters,
  },
}: TransactionFilterProps) => {
  const { orgId } = useOrg();

  const [accordionIndex, setAccordionIndex] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>(date__gte || '');
  const [endDate, setEndDate] = useState<string>(date__lte || '');
  const [selectedAddressStatus, setSelectedAddressStatus] = useState<string[]>(
    address_status__in?.split(',').filter(Boolean) || []
  );
  const [selectedTransactionType, setSelectedTransactionType] = useState<string[]>(
    transaction_type?.split(',').filter(Boolean) || []
  );
  const [selectedTransactionSource, setSelectedTransactionSource] = useState<string[]>(
    transaction_source?.split(',').filter(Boolean) || []
  );
  const [selectedState, setSelectedState] = useState<string>(state_code || '');
  const [selectedStatus, setSelectedStatus] = useState<string>(status || '');
  const [selectedFiling, setSelectedFiling] = useState<string>(filing_id || '');
  const [selectedMarketplace, setSelectedMarketplace] = useState(marketplace !== undefined && marketplace);
  const { data: registrations = {} } = useQuery<
    ApiResponse<RegistrationInstance>,
    null,
    Record<string, RegistrationInstance>
  >({
    queryKey: [REGISTRATION_STATE_KEY, orgId, 1, 100],
    queryFn: async () => {
      const { data } = await getRegistrations(orgId, { page: 1, size: 100 });
      return data;
    },
    select: (data: ApiResponse<RegistrationInstance>) => {
      return data.items.reduce<Record<string, RegistrationInstance>>((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },
    enabled: !!orgId,
  });

  const { data: filingOptions } = useQuery({
    queryKey: [FILINGS_STATE_KEY, orgId, 1, 100],
    queryFn: async () => {
      const { data } = await getFilings({ orgId, params: { page: 1, size: 100 } });
      return data.items;
    },
    select: data => {
      return data.map(({ start_date, end_date, registration_id, id }: FilingInstance) => {
        const stateName = registrations[registration_id]?.state_name ?? '';
        const date = `${toDateShort(start_date)} - ${toDateShort(end_date)}`;
        return {
          value: id,
          label: stateName ? `${stateName} (${date})` : date,
        };
      });
    },
    enabled: !!orgId,
    refetchOnWindowFocus: false,
  });

  const { data: fileSources = [] } = useQuery({
    queryKey: [...dataSourceQueryKeys.fileSources(), orgId],
    queryFn: async () => await getFileSources(orgId, true),
    select: response => {
      const { data: sources } = response;
      return Object.keys(sources)?.map(key => ({
        label: FILE_SOURCE_MAP[key as Source]?.title ?? getHumanReadableString(key),
        value: key as Source,
      }));
    },
  });

  const handleSave = () => {
    setFilters({
      address_status__in: selectedAddressStatus,
      status: selectedStatus,
      state_code: selectedState,
      filing_id: selectedFiling,
      transaction_type: selectedTransactionType,
      transaction_source: selectedTransactionSource,
      date__gte: startDate,
      date__lte: endDate,
      marketplace: selectedMarketplace ? 'true' : 'false',
    });
    onClose();
  };

  const handleClear = () => {
    setStartDate('');
    setEndDate('');
    setSelectedState('');
    setSelectedAddressStatus([]);
    setSelectedTransactionType([]);
    setSelectedTransactionSource([]);
    setSelectedStatus('');
    setSelectedFiling('');
    setSelectedMarketplace(false);
    setFilters({
      address_status__in: undefined,
      status: undefined,
      state_code: undefined,
      country: undefined,
      filing_id: undefined,
      transaction_type: undefined,
      transaction_source: undefined,
      date__gte: undefined,
      date__lte: undefined,
      marketplace: undefined,
    });
    onClose();
  };

  useEffect(() => {
    const newIndexes = [];
    if (state_code) newIndexes.push('state_code');
    if (transaction_type) newIndexes.push('transaction_type');
    if (transaction_source) newIndexes.push('transaction_source');
    if (address_status__in) newIndexes.push('address_status__in');
    if (filing_id) newIndexes.push('filing_id');
    if (status) newIndexes.push('status');
    if (date__gte || date__lte) newIndexes.push('date');
    if (marketplace) newIndexes.push('marketplace');
    setAccordionIndex(newIndexes);
  }, []);

  const hasFilter = Object.values({
    address_status__in,
    status,
    state_code,
    country,
    filing_id,
    transaction_type,
    transaction_source,
    date__gte,
    date__lte,
    marketplace,
  }).some(Boolean);

  return (
    <Stack overflow={'auto'} maxHeight={'500px'} color={'gray.900'}>
      <HStack position={'sticky'} top={0} bgColor={'#fff'} zIndex={1} justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight={500}>
          Filters
        </Text>
        {hasFilter && (
          <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
            Clear All Filters
          </Text>
        )}
      </HStack>
      <Stack>
        <AccordionRoot
          variant="plain"
          multiple
          value={accordionIndex}
          onValueChange={({ value }) => setAccordionIndex(value)}
        >
          <AccordionItem value="jurisdiction">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Jurisdiction
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent gap={2} mb={2}>
              <NativeSelectRoot>
                <NativeSelectField
                  value={selectedState}
                  onChange={e => setSelectedState(e.target.value)}
                  placeholder="Select"
                >
                  {getStatesOrProvinces(country || '').map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </NativeSelectField>
              </NativeSelectRoot>
            </AccordionItemContent>
          </AccordionItem>

          <AccordionItem value="transaction_type">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Transaction Type
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent gap={2} mb={2}>
              <CheckboxGroup
                value={selectedTransactionType}
                onValueChange={values => setSelectedTransactionType(values)}
              >
                {transactionTypeOptions.map(({ value, title }) => (
                  <Checkbox key={value} value={value}>
                    {title}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </AccordionItemContent>
          </AccordionItem>

          <AccordionItem value="transaction_source">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Transaction Source
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent gap={2} mb={2}>
              <CheckboxGroup
                value={selectedTransactionSource}
                onValueChange={values => setSelectedTransactionSource(values)}
              >
                {fileSources.map(({ value, label }) => (
                  <Checkbox key={value} value={value}>
                    {label}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </AccordionItemContent>
          </AccordionItem>

          <AccordionItem value="address_status__in">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Address Status
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent gap={2} mb={2}>
              <CheckboxGroup value={selectedAddressStatus} onValueChange={values => setSelectedAddressStatus(values)}>
                {addressStatusOptions.map(({ value, title }) => (
                  <Checkbox key={value} value={value}>
                    {title}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </AccordionItemContent>
          </AccordionItem>

          <AccordionItem value="filing_id">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Filing
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent gap={2} mb={2}>
              <NativeSelectRoot>
                <NativeSelectField value={selectedFiling} onChange={e => setSelectedFiling(e.target.value)}>
                  <>
                    <option value={''}>Select Filing</option>
                    {filingOptions?.map(({ value, label }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </>
                </NativeSelectField>
              </NativeSelectRoot>
            </AccordionItemContent>
          </AccordionItem>

          <AccordionItem value="status">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Status
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent mb={2}>
              <NativeSelectRoot>
                <NativeSelectField value={selectedStatus} onChange={e => setSelectedStatus(e.target.value)}>
                  <>
                    <option value={''}>Select Status</option>
                    {statusOptions.map(({ value, title }) => (
                      <option key={value} value={value}>
                        {title}
                      </option>
                    ))}
                  </>
                </NativeSelectField>
              </NativeSelectRoot>
            </AccordionItemContent>
          </AccordionItem>
          <AccordionItem value="marketplace">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Marketplace
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent mb={2}>
              <Checkbox
                checked={selectedMarketplace}
                onCheckedChange={({ checked }) => setSelectedMarketplace(checked as boolean)}
              >
                Marketplace
              </Checkbox>
            </AccordionItemContent>
          </AccordionItem>
          <AccordionItem value="date">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Date
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent gap={4} mb={2}>
              <Box>
                <Text fontWeight={'medium'} fontSize={'sm'} mb={1}>
                  Start Date
                </Text>
                <DatePicker
                  selected={startDate}
                  onChange={date => {
                    setStartDate(date);
                  }}
                />
              </Box>
              <Box>
                <Text fontWeight={'medium'} fontSize={'sm'} mb={1}>
                  End Date
                </Text>
                <DatePicker
                  selected={endDate}
                  onChange={date => {
                    setEndDate(date);
                  }}
                />
              </Box>
            </AccordionItemContent>
          </AccordionItem>
        </AccordionRoot>
        <HStack direction="row" justifyContent={'space-around'} gap="18px" mt={4}>
          <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
            Cancel
          </Button>
          <Button variant="solid" color="primary" onClick={handleSave} width={'132px'} height={'32px'}>
            Apply
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
};
